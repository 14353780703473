export const ProjectsData = {
  data: [
    {
      id: "R_kgDOJWL0-g",
      name: "Hari Shankar Jain  ",
      createdAt: "2024-03-02T04:18:19Z",
      url: "https://www.youtube.com/watch?v=K6RXGGl_zMY&t=5s",
      description:
        "🤝Ram Mandir Advocate: Presenting Lawyer Of The Century Hari Shankar Jain",
      image:
        "images/guest+client/IMG_20240715_142735.jpg",
      languages: [
        {
          name: "youtube",
          iconifyClass: "logos-youtube",
        },
      ],
    },
    {
      id: "R_kgDOG_vYHA",
      name: "Dr. Anand Ranganathan",
      createdAt: "2023-07-15T20:41:33Z",
      url: "https://www.youtube.com/watch?v=jZ0R-LRq5GI",
      description: "🔥Exposes Bjp, Mahatma Gandhi and the Political industry",
      image:
        "images/guest+client/Screenshot_2023-12-31-21-53-46-98_92460851df6f172a4592fca41cc2d2e6.jpg",
      languages: [
        {
          name: "youtube",
          iconifyClass: "logos-youtube",
        },
      ],
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: " Ashok Kantha",
      createdAt: "2023-09-29T16:26:54Z",
      url: "https://www.youtube.com/watch?v=bP05-O0N4WU",
      description:
        "🌏Unveiling Diplomatic Secrets with Ashok Kantha of China, Sri Lanka and More",
      image:
      "images/guest+client/Screenshot_2023-12-31-21-55-14-59_92460851df6f172a4592fca41cc2d2e6.jpg",
      languages: [
        {
          name: "youtube",
          iconifyClass: "logos-youtube",
        },
      ],
    },
    {
      id: "R_kgDOG0_8uQ",
      name: " Nitin Gokhale",
      createdAt: "2024-01-19T14:50:20Z",
      url: "https://www.youtube.com/watch?v=BaRhsS-8Cnc",
      description:
        "🌀 Defending the Nation: Insights with Nitin Gokhale on India's Defense and Security",
      image:
        "../../images/guest+client/Screenshot_2023-12-31-21-55-44-98_92460851df6f172a4592fca41cc2d2e6.jpg",
      languages: [
        {
          name: "youtube",
          iconifyClass: "logos-youtube",
        },
      ],
    },
    {
      id: "R_kgDOGcPKSQ",
      name: " Sushil Pandit",
      createdAt: "2024-01-05T17:56:07Z",
      url: "https://www.youtube.com/watch?v=zZO3-g7d1OU",
      description:
        "🚀 History of Kashmir: Sushil Pandit on POK, Radical Islamism and Pakistan",
      image:
        "images/guest+client/Screenshot_2023-12-31-21-59-51-31_92460851df6f172a4592fca41cc2d2e6.jpg",
      languages: [
        {
          name: "youtube",
          iconifyClass: "logos-youtube",
        },
      ],
    },
    {
      id: "R_kgDOGcPKSQ",
      name: "  Colonel Daljeet",
      createdAt: "2024-07-08T17:56:07Z",
      url: "https://youtu.be/Q5NCY7ERbuQ?si=92LvTP-ZaRtMJA15",
      description:
        "How Terrorism is Driving Pakistan to Collapse!",
      image:
        "images/guest+client/IMG_20240523_233330.jpg",
      languages: [
        {
          name: "youtube",
          iconifyClass: "logos-youtube",
        },
      ],
    },
  ],
};
