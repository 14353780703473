const settings = {
  isSplash: false,
};

//SEO Related settings
const seo = {
  title: "JAI-Lawyer",
  description:
    "A passionate legal professional at the Supreme Court of India, focused on creating sustainable and scalable legal frameworks to address complex issues and drive meaningful impact.",
  og: {
    title: "Jai Portfolio",
    type: "website",
    url: "http://Advocatejaieesh.in/",
  },
};

//Home Page
const greeting = {
  title: "JAI EESH",
  logo_name: "jai eesh",
  nickname: "ADVOCATE, SUPREME COURT OF INDIA",
  subTitle:
    "A passionate legal professional at the Supreme Court of India, focused on creating sustainable and scalable legal frameworks to address complex issues and drive meaningful impact.",
};

const socialMediaLinks = [
  {
    name: "LinkedIn",
    link: "#",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://youtube.com/@jaiandbharat35?si=xlihGVOqYjZi0WXV",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:jaieeshadvocate@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "X-Twitter",
    link: "https://twitter.com/Jaieeshadv?t=6NiTKOvy7aREx9uXPG_IsA&s=08",
    fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },
  {
    name: "Facebook",
    link: "#",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link:
      "https://www.instagram.com/___jai22?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==s",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      skills: [],
      softwareSkills: [],
    },
    {
      skills: [],
      softwareSkills: [],
    },
    {
      skills: [],
      softwareSkills: [],
    },
    {
      skills: [],
      softwareSkills: [],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [],
};

const degrees = {
  degrees: [
    {
      title: "Indian Institute of Information Technology Kurnool",
      subtitle: "B.Tech. in Computer Engineering",
      logo_path: "iiitk_logo.png",
      alt_name: "IIITDM Kurnool",
      duration: "2016 - 2020",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Deep Learning, Data Science, Cloud Computing and Full Stack Development.",
        "⚡ I was selected for Merit cum Means Scholarship which is given to top 10% of students in college. I have received award from respected director for consistently best performance in academics.",
      ],
      website_link: "http://iiitk.ac.in",
    },
    {
      title: "Indiana University Bloomington",
      subtitle: "M.S. in Computer Science",
      logo_path: "iu_logo.png",
      alt_name: "Indiana University Bloomington",
      duration: "2021 - 2023",
      descriptions: [
        "⚡ I have taken varity of courses related to Artificial Intelligence which correspond to Explainable AI, Graph Machine Learning, Computer Vision etc.",
        "⚡ Apart from this, I have also done research assistantship. As part of it, I have worked on creating new algorithms in Graph ML and Network Science.",
        "⚡ During my time at university, I was also associated with multimedia department. As part of it, I have worked on some documentry films and interviews.",
      ],
      website_link: "https://www.indiana.edu/",
    },
  ],
};

const certifications = {
  certifications: [],
};

// Experience Page
const experience = {
  title: "What I Do?",
  subtitle: "Advocating for Justice",
  description:
    "Welcome to the legal realm guided by expertise, integrity, and unwavering commitment to justice. I am [JAI EESH], an advocate with a distinguished background in law with a Bachelor of Laws (B.A., LL.B) degree. As a legal professional, I stand as a practitioner before the apex court of the nation, the Supreme Court of India, along with the esteemed Delhi High Court and Punjab and Haryana High Court.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Legal Practitioner & Advocate",
          company: "SUPREME COURT OF INDIA",
          company_url: "https://main.sci.gov.in/",
          logo_path: "unnamed.png",
          duration: "Presently working",
          location: "Mandi House, New Delhi",
          description:
            "My expertise extends across a spectrum of legal domains, ranging from civil disputes to criminal litigations, and from intricate taxation matters to complex corporate affairs. Whether representing individuals, businesses, or organizations, I approach each case with diligence, integrity, and an unwavering dedication to securing favorable outcomes for my clients.As you navigate through the complexities of legal challenges, I am here to be your trusted ally, guiding you through every step of the legal process with proficiency, empathy, and a commitment to your best interests.Thank you for considering me as your legal representative. Together, let us embark on a journey towards justice, empowerment, and legal triumph",
          color: "#0879bf",
        },
        {
          title: "Advocate & Legal Consultant",
          company: "HIGH COURT OF DELHI",
          company_url: "https://delhihighcourt.nic.in/",
          logo_path: "delhi-high-court-Logo.jpg",
          duration: "Presently working",
          location: "India Gate, New Delhi",
          description:
            "I am a seasoned lawyer with a strong background in civil disputes, criminal litigations, taxation, and corporate affairs. With diligence and integrity, I represent individuals, businesses, and organizations, striving for favorable outcomes in every case Navigating legal challenges demands proficiency, empathy, and commitment. As your trusted ally, I guide you through the process with dedication to your best interests.Let's embark on a journey towards justice, empowerment, and legal triumph together.",
          color: "#0879bf",
        },
        {
          title: "Legal Adviso & Advocate",
          company: "HIGH COURT OF CHANDIGARH",
          company_url: "https://highcourtchd.gov.in/",
          logo_path: "Punjab_and_Haryana_High_Court_Logo.png",
          duration: "Presently working",
          location: "Capitol Complex, Sector 1, Chandigarh",
          description:
            "I am a dedicated lawyer with experience at Chandigarh High Court, specializing in civil disputes, criminal litigations, taxation matters, and corporate affairs. I approach each case with diligence, integrity, and a commitment to securing favorable outcomes for my clients.As you navigate legal challenges, I'm here to be your trusted ally, guiding you with proficiency, empathy, and a focus on your best interests. Thank you for considering me as your legal representative. Let's work together towards justice and legal success..",
          color: "#0879bf",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "BEYOND THE COURTROOM ",
  description:
    "Besides being a practicing attorney, I extend my reach as the Co–Host of JAI AND BHARAT SHOW, a thought-provoking You Tube Podcast platform where I engage in thought provoking conversations with eminent personalities from diverse backgrounds, from Senior Supreme Court Lawyers to Government Officials, Politicians, Army Veterans, Scientists, Professors, Doctors, and Social Activists, where each episode delves into National, Economic and Social Interest issues, fostering dialogue and promoting informed discourse on matters shaping our nation's trajectory.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "file (1).png",
    description:
      "I am accessible through various communication channels. You can reach out to me, and I will respond promptly within 24 hours. I provide assistance in legal matters, including litigation, contracts, intellectual property, corporate law, and regulatory compliance.",
  },
  blogSection: {
    title: "Book Lawyer Consultation",
    subtitle:
      "Navigate through complex legal matters with confidence through expert consulting services, guiding you towards informed decisions and successful resolutions.",
  },
  addressSection: {
    title: "",
  },
  phoneSection: {
    title: "☎️ Phone: 8360835395",
    subtitle: "📧 Email: JAIEESHADVOCATE@GMAIL.COM",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
